import {
  AUTH_PROVIDER,
  CHAIN,
  getRamperSigner,
  getUser,
  getWalletModel,
  init,
  openWallet,
  sendToken,
  signIn,
  signInWithProvider,
  signInWithRedirect,
  signOut,
  User,
  WALLET_TABS,
} from '@ramper/ethereum'
import { ethers } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { AUTH_HOST_ENV } from './utils/internal'
;(window as any).RAMPER_ENV = process.env.REACT_APP_RAMPER_ENV || 'local'

// const ref = ''.concat(...(process.env.REACT_APP_VERCEL_GIT_COMMIT_REF || '').replace('/', '-').split('_'))
const AUTH_HOST = AUTH_HOST_ENV[process.env.REACT_APP_RAMPER_ENV! as 'local' | 'preview' | 'staging' | 'prod' | 'dev']

const alchemy = new ethers.providers.AlchemyProvider(80001, 'pEWvHrkSkkyWGZmezdGMk_LjYu8DAx1k')

function App() {
  const [user, setUser] = useState<User | null>(getUser())
  const [lastResult, setLastResult] = useState<string>('')
  const wallet = useMemo(() => {
    return user ? getWalletModel(window.localStorage, CHAIN.ETHEREUM) : null
  }, [user])
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [transactionResult, setTransactionResult] = useState<any>(null)
  const [signerResult, setSignerResult] = useState<any>(null)

  useEffect(() => {
    const user = new URLSearchParams(window.location.search).get('user')
    console.log('user', user)
    if (user) {
      console.log('parsed', JSON.parse(user))
      const parsed = JSON.parse(user)
      setIsSignedIn(true)
      setUser(parsed)
    }
    // if (user) {

    const listener = (e: MessageEvent) => {
      if (e.data.action !== 'signInResponse') {
        return
      }

      const { type, message } = e.data
      setIsSignedIn(type === 'success')

      if (type === 'success') {
        const user = JSON.parse(message) as User
        setUser(user)
        window.localStorage.setItem('ramper_loggedInUser', message)
      }
      window.removeEventListener('message', listener)
    }
    window.addEventListener('message', listener)
  }, [])

  const handleInit = async () => {
    const instance = await init({
      appName: 'Polygon Test App',
      appId: RAMPER_ENV === 'prod' || RAMPER_ENV === 'staging' ? 'dtofgevaxu' : 'suyklxmori',
      walletProviders: ['metamask'],
      walletTabs: [WALLET_TABS.NFT, WALLET_TABS.COIN],
      defaultTokenAddresses: ['0x514910771af9ca656af840dff83e8264ecf986ca'],
      theme: 'dark',
      network: 'maticmum',
      authProviders: ['google', 'facebook', 'twitter', 'apple', 'email'],
      language: 'en',
      issueIdToken: true,
    })
    console.log('initialized', instance.isLoaded)
    setIsLoaded(instance.isLoaded)
  }

  const handleInitWithKR = async () => {
    const instance = await init({
      appName: 'Polygon Test App',
      appId: RAMPER_ENV === 'prod' || RAMPER_ENV === 'staging' ? 'dtofgevaxu' : 'suyklxmori',
      issueIdToken: true,
      walletProviders: ['metamask'],
      walletTabs: [WALLET_TABS.NFT, WALLET_TABS.COIN],
      defaultTokenAddresses: ['0x514910771af9ca656af840dff83e8264ecf986ca'],
      theme: 'light',
      network: 'maticmum',
      authProviders: ['google', 'facebook', 'twitter', 'apple', 'email'],
      language: 'kr',
    })
    console.log('initialized', instance.isLoaded)
    setIsLoaded(instance.isLoaded)
  }

  const handleInitWithRinkebyNetwork = async () => {
    const instance = await init({
      appName: 'Polygon Test App',
      appId: RAMPER_ENV === 'prod' || RAMPER_ENV === 'staging' ? 'dtofgevaxu' : 'suyklxmori',
      walletProviders: ['metamask'],
      walletTabs: [WALLET_TABS.NFT, WALLET_TABS.COIN],
      defaultTokenAddresses: ['0x514910771af9ca656af840dff83e8264ecf986ca'],
      theme: 'dark',
      network: {
        blockchain: CHAIN.ETHEREUM,
        rpcURL: 'https://rpc.ankr.com/eth_rinkeby',
        name: 'Rinkeby',
        chainId: 4,
      },
      authProviders: ['google', 'facebook', 'twitter', 'apple', 'email'],
      language: 'en',
    })
    console.log('initialized', instance.isLoaded)
    setIsLoaded(instance.isLoaded)
  }

  const handleInitAndSignIn = async () => {
    const instance = await init({
      appName: 'Polygon Test App',
      walletProviders: ['metamask'],
      defaultTokenAddresses: ['0x514910771af9ca656af840dff83e8264ecf986ca'],
      theme: 'dark',
      network: 'maticmum',
      authProviders: ['google', 'facebook', 'twitter', 'apple', 'email'],
    })
    const signInResult = await signIn()

    setUser(signInResult.user ?? null)
    setIsLoaded(instance.isLoaded)
    console.log('handleInitAndSignIn done', instance)
  }

  const handleSignIn = async () => {
    const signInResult = await signIn()
    setLastResult(JSON.stringify(signInResult))
    if (signInResult.user) {
      setUser(signInResult.user)
      setIsSignedIn(true)
    }
  }

  const handleSendToken = async () => {
    try {
      const result = await sendToken({
        to: '0xa419dfa199Df8651c3f4476546AF5E4CC4E0F73F',
        value: '0.000001',
        network: 'maticmum',
      })
      console.log('sendToekn result: ', result)
      setTransactionResult(result)
    } catch (e) {
      console.error(e)
      setTransactionResult(JSON.stringify(e))
    }
  }

  const handleSendTransactionWithRamperSigner = async () => {
    if (!wallet) {
      console.log('No wallet')
      return
    }

    const signer = await getRamperSigner(alchemy)

    const value = ethers.utils.parseEther('0.0000001')
    const gasLimit = await alchemy.estimateGas({
      to: '0xa419dfa199Df8651c3f4476546AF5E4CC4E0F73F',
      value,
    })
    const feeData = await alchemy.getFeeData()

    try {
      const result = await signer.sendTransaction({
        type: 2,
        from: wallet.publicKey,
        to: '0xa419dfa199Df8651c3f4476546AF5E4CC4E0F73F',
        value,
        chainId: 80001,
        nonce: alchemy.getTransactionCount(wallet.publicKey),
        gasLimit: gasLimit,
        maxFeePerGas: feeData.maxFeePerGas,
        maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
      })

      console.log('sendTransaction result: ', result)

      setSignerResult(result)
    } catch (e) {
      console.error(e)
      setSignerResult(JSON.stringify(e))
    }
  }

  const handleSignTransactionWithRamperSigner = async () => {
    if (!wallet) {
      console.log('No wallet')
      return
    }

    const signer = await getRamperSigner(alchemy)

    const value = ethers.utils.parseEther('0.0000001')
    const gasLimit = await alchemy.estimateGas({
      to: '0xa419dfa199Df8651c3f4476546AF5E4CC4E0F73F',
      value,
    })
    const feeData = await alchemy.getFeeData()

    try {
      const result = await signer.signTransaction({
        type: 2,
        from: wallet.publicKey,
        to: '0xa419dfa199Df8651c3f4476546AF5E4CC4E0F73F',
        value,
        chainId: 80001,
        nonce: alchemy.getTransactionCount(wallet.publicKey),
        gasLimit: gasLimit,
        maxFeePerGas: feeData.maxFeePerGas,
        maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
      })

      console.log('signTransaction result: ', result)

      setSignerResult(result)
    } catch (e) {
      console.error(e)
      setSignerResult(JSON.stringify(e))
    }
  }

  const handleSignMessageWithRamperSigner = async () => {
    if (!wallet) {
      console.log('No wallet')
      return
    }

    const signer = await getRamperSigner(alchemy)

    const message = 'Ramper sign message test'

    try {
      const result = await signer.signMessage(message)

      console.log('signTransaction result: ', result)

      setSignerResult(result)
    } catch (e) {
      console.error(e)
      setSignerResult(JSON.stringify(e))
    }
  }

  const handleSignOut = () => {
    signOut()
    setUser(null)
  }

  const handleOpenWalletView = () => {
    openWallet()
  }

  const handleTestSignIn = () => {
    window.open(AUTH_HOST + `/signin/test?chainName=${CHAIN.ETHEREUM}`)
  }

  const handleSignInWithRedirect = () => {
    signInWithRedirect({
      redirectURL: window.location.href,
    })
  }

  const directSignIn = async (provider: string) => {
    setLastResult(
      JSON.stringify(
        await signInWithProvider({
          provider,
        }),
      ),
    )
  }

  return (
    <div
      style={{
        width: '100%',
        padding: '20px 16px',
        boxSizing: 'border-box',
        paddingTop: '64px',
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: '64px',
          background: 'blue',
          zIndex: 9999,
          color: 'white',
          textAlign: 'center',
          fontSize: '1.6rem',
        }}
      >
        z-index test
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h2>Ramper Ethereum Example</h2>
        <button onClick={handleInit}>init only</button>
        <br />
        <button onClick={handleInitWithKR}>init with KR and issueIdToken</button>
        <br />
        <button onClick={handleInitWithRinkebyNetwork}>init with Rinkeby network</button>
        <br />
        <button onClick={handleInitAndSignIn}>init and sign in for testing</button>
        <br />
        <p>Is Ramper Bundle loaded? {isLoaded ? 'YES' : 'NO'}</p>
        <h3>Sign In with Redirect</h3>
        <button onClick={handleSignInWithRedirect}>Email</button>
        <h3>Direct Sign In</h3>
        <table>
          <tbody>
            <tr>
              <td>
                <button
                  onClick={(e) => {
                    directSignIn(AUTH_PROVIDER.GOOGLE)
                  }}
                >
                  Google
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => {
                    directSignIn(AUTH_PROVIDER.FACEBOOK)
                  }}
                >
                  Facebook
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => {
                    directSignIn(AUTH_PROVIDER.TWITTER)
                  }}
                >
                  Twitter
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => {
                    directSignIn(AUTH_PROVIDER.APPLE)
                  }}
                >
                  Apple
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => {
                    directSignIn(AUTH_PROVIDER.EMAIL)
                  }}
                >
                  Email
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <p>Sign In Result: {lastResult}</p>
        <button onClick={handleSignIn}>Sign in</button>
        <br />
        <button onClick={handleTestSignIn}>Sign in with test account</button>
        <br />
        <button onClick={handleSignOut}>Sign out</button>
        <p>Signed in? {isSignedIn && user ? 'YES, ' + user.UID : 'NO'}</p>
        <p>Wallet address: {wallet?.publicKey}</p>
        <button onClick={handleSendToken}>Test sendToken</button>
        <p>Transaction result: {JSON.stringify(transactionResult)}</p>
        <button onClick={handleOpenWalletView}>Open WalletView</button>
        <h3>Ramper signer</h3>
        <button onClick={handleSendTransactionWithRamperSigner}>Test sendTransaction</button>
        <br />
        <button onClick={handleSignTransactionWithRamperSigner}>Test signTransaction</button>
        <br />
        <button onClick={handleSignMessageWithRamperSigner}>Test signMessage</button>
        <p>Signer result: {JSON.stringify(signerResult)}</p>
      </div>
    </div>
  )
}

export default App
