export const isServer = () => globalThis.window === undefined

export const getHostname = () => (isServer() ? 'localhost' : window.location.hostname)

export const AUTH_HOST_ENV = {
  local: `http://${getHostname()}:3000`,
  preview: `https://auth.v1.preview.ramper.xyz`,
  dev: 'https://auth.v1.dev.ramper.xyz',
  staging: 'https://auth.v1.staging.ramper.xyz',
  prod: 'https://auth.v1.ramper.xyz',
}
